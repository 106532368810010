import React, { useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Flex,
  Divider
} from "@chakra-ui/react"
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { useGetAllUserData, useEventNonce } from "../hooks"
import EventDetail from "./EventDetail"
import { comma } from "../constant/comma"

export default function EventListTable(props: any) {
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10**18
  const [eventId, setEventId] = useState(0)
  // アカウントアドレス取得
  const account = props.account
  // 参加したイベント情報取得
  const yourDataList = useGetAllUserData(account)

  // 全イベント数取得
  const eventNum = useEventNonce()

  // イベント数を元にイベントIDの配列作成
  const allEventIds = eventNum ? [...Array(parseInt(eventNum)).keys()] : null

  // 初回表示時イベント詳細は非表示
  // eventIdに初期値を代入する必要があり、idが0のデータが表示されてしまうので
  // infoボタンをクリックするまではイベント詳細は非表示にしたい
  const [initView, setViewState] = useState(true)

  // テーブル用にデータ調整
  const eventDataSet: any = [] 

  // 取得したイベントデータを元に各配列データ作成
  if (yourDataList && yourDataList[0] && allEventIds) {
    /**
     * 下記のような連想配列を作成
     * [
     *   イベントID: [
     *      イベントID, イベント名, 送金額
     *   ]
     * ]
     */
    yourDataList[0].map(function(val: string) {
      const eventId = parseInt(val[0])
      const eventName = val[1]
      const depositAmount = parseInt(val[2])/convertedNum
      eventDataSet[eventId] = [eventId, eventName, depositAmount]
    })

    // 送金歴の無いイベントにイベントIDのみ入った配列を格納
    for (let i = 0; i < eventNum; i++) {
      // イベントIDがiに送金歴があるかを判定
      // 無いときはeventName（イベント名）とdepositAmount(送金額)が
      // 空の配列を作成
      if (eventDataSet[i] === undefined) {
        eventDataSet[i] = [i, null, null]
      }
    }
    
    // IDが大きい順に並び替え
    eventDataSet.reverse()
  }

  // クリックするとイベントIDを取得
  function handleClick(e: any, val: any) {
    e.preventDefault()
    // イベントID
    // 詳細を開いている時はクリックしたデータIDとeventIdが同じになるので、
    // eventIdは0、initViewはtrueにして一旦初期化して詳細を閉じてしまう
    const argValue = parseInt(val) !== eventId ? parseInt(val): 0
    const viewInitialize = !initView && parseInt(val) === eventId ? true: false
    // イベントIDをセット
    setEventId(argValue)
    // 初期表示解除
    setViewState(viewInitialize)

    // 該当箇所までスクロール
    // scrollBottomRef?.current?.scrollIntoView()
  }

  // イベント名や送金額のあるイベントデータはそのまま表示
  // 送金歴が無い場合、NOT DEPOSIT YETを表示
  // その場合、infoを押すと新規depositが可能
  const tUpperBody = eventDataSet.map((val: any) => {
    return (eventId <= val[0]) || initView? (
      <Tr key={'upper_' + val[0].toString()}>
        <Td color="white">{val[0]}</Td>
        {
          val[1] && val[2] 
          ?
          <>
          <Td color="white">{ val[1] }</Td>
          <Td color="white">{ comma(val[2]) }</Td>
          </>
          : 
          <>
          <Td color="white">NOT DEPOSITED YET</Td>
          <Td color="white">NOT DEPOSITED YET</Td>
          </>
        }

        <Td color="white" isNumeric>
          <Button 
            rightIcon={eventId === val[0] && !initView ? <ArrowUpIcon />: <ArrowDownIcon />} 
            colorScheme="teal"
            variant={eventId === val[0] && !initView ? "solid" : "outline"}
            size="xs"
            onClick={ e => handleClick(e, val[0]) }
          >
            Info
          </Button>
        </Td>
      </Tr>
    ): <></>
  })

  const tLowerBody = eventDataSet.map((val: any) => {
    return !initView && (eventId > val[0])? (
      <Tr key={'lower_' + val[0].toString()}>
        <Td color="white">{val[0]}</Td>
        {
          val[1] && val[2] 
          ?
          <>
          <Td color="white">{ val[1] }</Td>
          <Td color="white">{ comma(val[2]) }</Td>
          </>
          : 
          <>
          <Td color="white">NOT DEPOSITED YET</Td>
          <Td color="white">NOT DEPOSITED YET</Td>
          </>
        }

        <Td color="white" isNumeric>
          <Button 
            rightIcon={<ArrowDownIcon />} 
            colorScheme="teal" 
            variant="outline" 
            size="xs"
            onClick={ e => handleClick(e, val[0]) }
          >
            Info
          </Button>
        </Td>
      </Tr>
    ): <></>
  })

  // テーブル描画
  return account ? (
    <Flex direction="column" align="center" mt="12">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Dream List</Th>
            <Th>Deposit</Th>
            <Th isNumeric>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          { tUpperBody }
        </Tbody>
      </Table>

      {
        initView 
        ? <></>
        : <EventDetail eventId = { eventId } account = { account } />
      }
      <Divider size="10" />
      <Table variant="simple">
        <TableCaption>All the events list</TableCaption>
        <Tbody>
          { 
            initView 
            ? <></>
            : tLowerBody
          }
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>ID</Th>
            <Th>Dream List</Th>
            <Th>Deposit</Th>
            <Th isNumeric>Action</Th>
          </Tr>
        </Tfoot>
      </Table>
    </Flex>
  ): <></>
}