import { Formik, Form, Field, FieldProps } from 'formik'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/react"
import { useDeposit, useEventList } from "../hooks"
import { BigNumber } from 'bignumber.js'


export default function DepositModal( props: any ) {
  // 値がdecimal18桁なのでこの定数で割って調整
  const convertedNum = 10**18

  // モーダルの開閉
  const { isOpen, onOpen, onClose } = useDisclosure()
  // イベントID取得
  const eventId = props.eventId
  // イベント詳細データ
  const eventData = useEventList(eventId)
  // depositメソッド準備
  const { state, send: sendDeposit } = useDeposit(0,'0')

  // 送金
  async function handleDeposit(value: any) {
    // 指数表記になるのを防ぐため、biguNumberに変換
    const bigNum = new BigNumber(value.depositAmount * convertedNum)
    // 桁数が大きいとmetamaskでover-flowになるのでstringに変換
    const stringfiedNum = bigNum.toFixed().toString()
    // depositメソッド
    await sendDeposit(eventId, stringfiedNum)
    // console.log(state)
    // レンダリングのタイミングでエラーになるので一旦リロード
    if (state.status !== "None") {
      window.location.reload()
    }
  }
  
  // 送金額の上限と下限チェック
  function validateAmount(value:number) {
    if (value === 0 || !value ) return
    const maxLimit = parseInt(eventData[4]) 
    const minLimit = parseInt(eventData[5])

    const currentTime = new Date()
    // 時間をunixから変換
    const unixTime : number = eventData[6]
    const limitDateUnix: Date = new Date(unixTime * 1000)
    // タイマーが0になったら上限バリデーションはなし
    const differentTIme = limitDateUnix.getTime() - currentTime.getTime()
    
    let error
    if ( (value * convertedNum > maxLimit) && (differentTIme > 0) ) {
      error = "Jeez! Too hight amount 😱"
    } else if ( value * convertedNum < minLimit ) {
      error = "Jeez! Too low amount 😱"
    }
    return error
  }


  return (
    <>
      <Button onClick={onOpen}>Deposit</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={{ depositAmount: '' }}
            onSubmit={(value, actions) => {
              setTimeout(() => {
                handleDeposit(value)
                actions.setSubmitting(false)
              }, 1000)
            }}
          >
          {(props) => (
            <Form>
              <ModalHeader></ModalHeader>
              <ModalBody>
                <Field name="depositAmount" validate={validateAmount}>
                  {({ field, form } : FieldProps) => (
                    <FormControl isInvalid={Boolean(form.errors.depositAmount) && Boolean(form.touched.depositAmount)}>
                      <FormLabel htmlFor="depositAmount">Deposit Amount</FormLabel>
                      <Input {...field} id="depositAmount" placeholder="Enter" />
                      <FormErrorMessage>{form.errors.depositAmount}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button 
                  colorScheme="green"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Deposit
                </Button>
              </ModalFooter>
            </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}

